/* istanbul ignore file */
import {
  createApi,
  fetchBaseQuery,
  FetchBaseQueryError,
  retry,
} from "@reduxjs/toolkit/query/react";
import { isTesting } from "src/helpers/constants";
import { security } from "../helpers/security";
import {
  GraphResponse,
  GraphRequest,
  GraphSearchResponse,
  GraphSearchRequest,
} from "src/types/Graph";
import { EventResponse } from "src/types/EventResponse";
import { EventRequest } from "src/types/EventRequest";
import { EventFields } from "src/types/EventFields";
import { EventGroupByResponse } from "src/types/EventGroupByResponse";
import { EventGroupByRequest } from "src/types/EventGroupByRequest";
import { SuggestionRequest } from "src/types/SuggestionRequest";
import { SuggestionResponse } from "src/types/SuggestionResponse";
import { ListItemResponse } from "src/types/ListsResponse";
import { Profile } from "src/types/Profile";
import { Metrics } from "src/types/Metrics";
import { Client } from "src/types/Client";
import { VerificationProvidersResponse } from "src/types/VerificationProvider";
import { ListInfo } from "src/types/ListInfo";
import { ListAttributesResponse } from "src/types/ListAttribute";
import {
  InsightsResponse,
  Threat,
  ThreatResponse,
} from "src/types/InsightsResponse";
import { InsightsFeedback } from "src/types/InsightsFeedback";
import { AttributeRequest } from "src/types/AttributeRequest";
import { AttributeResponse } from "src/types/AttributeResponse";
import { GetInvitationsResponse } from "src/types/GetInvitationsResponse";
import { GetWorkspaceUsersResponse } from "src/types/GetWorkspaceUsersResponse";
import { UpdateWorkspaceUserRequest } from "src/types/UpdateWorkspaceUserRequest";
import { UpdateWorkspaceUserResponse } from "src/types/UpdateWorkspaceUserResponse";
import { UpdateInvitationResponse } from "src/types/UpdateInvitationResponse";
import { UpdateInvitationRequest } from "src/types/UpdateInvitationRequest";
import { CreateInvitationsResponse } from "src/types/CreateInvitationResponse";
import { CreateInvitationsRequest } from "src/types/CreateInvitationsRequest";
import { DataDictionaryResponse } from "src/types/DataDictionaryResponse";
import { NotificationType } from "src/types/Notification";
import { IAMProvider } from "src/types/IAMProvider";

export const fftIdConfig = {
  proto: process.env.REACT_APP_443ID_PROTO ?? "http",
  uri: process.env.REACT_APP_443ID_URI ?? "127.0.0.1",
  port: process.env.REACT_APP_443ID_PORT ?? "3005",
  version: process.env.REACT_APP_443ID_VERSION ?? "v1",
};

function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
  return (
    error !== null &&
    typeof error === "object" &&
    "status" in error &&
    typeof error.status !== "undefined"
  );
}

export const baseUrl = `${fftIdConfig.proto}://${fftIdConfig.uri}:${fftIdConfig.port}/v1`;
let maxRetries = 0;
const baseQuery = retry(
  // Retry only fires when the retryCondition is met
  fetchBaseQuery({
    baseUrl,
    prepareHeaders: async (headers) => {
      if (isTesting) {
        return headers;
      }

      const clientUuid = security.getClientUuid();
      const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
      const scope = `client:${clientUuid}`;
      let token;
      const getToken = security.getAccessTokenSilently();

      if (clientUuid) {
        //NOSONAR
        token = await getToken({ authorizationParams: { scope, audience } });
      } else {
        //NOSONAR
        token = await getToken({ authorizationParams: { audience } });
      }

      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
    credentials: "include",
  }),
  {
    retryCondition: function (error: unknown): boolean {
      maxRetries++;
      if (!isFetchBaseQueryError(error) || typeof error.status === "string") {
        return false;
      }

      return error.status >= 500 && error.status < 600 && maxRetries <= 2;
    },
  }
);

export const fftIdApi = createApi({
  reducerPath: "fftIdApi",
  baseQuery,
  tagTypes: [
    "Profile",
    "Client",
    "Connection",
    "Metrics",
    "Rule",
    "RulesDictionary",
    "Tags",
    "VerificationProviders",
    "Users",
    "Invitations",
    "ExcludeFilters",
    "Lists",
    "Insights",
    "Threats",
    "Notifications",
    "IdentityIntegrations",
    "IdentityProvider",
    "SiemIntegrations",
  ],
  endpoints: (builder) => ({
    getStripeURL: builder.query({
      query: ({ type, term }) => {
        const url = "/client-services-api/stripe";
        if (type && term) {
          return `${url}/${type}?term=${term}`;
        }
        if (type) {
          return `${url}/${type}`;
        }
        return url;
      },
    }),
    getApplications: builder.query({
      query: () => "/client-services-api/application?application_uuid=all",
    }),
    getTypeahead: builder.query({
      query: ({ type, search }) =>
        `/signalprint/reporting/typeaheadsearch?type=${type}&search=${search}`,
    }),
    postGraphSearch: builder.mutation<
      GraphSearchResponse[],
      GraphSearchRequest
    >({
      query: (graphSearchRequest) => ({
        url: "/signalprint/reporting/search",
        body: graphSearchRequest,
        method: "POST",
      }),
    }),
    getEventFields: builder.query<EventFields, void>({
      query: () => `/events/fields`,
    }),
    postSuggestions: builder.mutation<SuggestionResponse, SuggestionRequest>({
      query: (eventRequest) => ({
        url: "/events/suggestions",
        body: eventRequest,
        method: "POST",
      }),
    }),
    postEventSearch: builder.mutation<EventResponse, EventRequest>({
      query: (eventRequest) => ({
        url: "/events/events",
        body: eventRequest,
        method: "POST",
      }),
    }),
    postEventGroupSearch: builder.mutation<
      EventGroupByResponse,
      EventGroupByRequest
    >({
      query: (eventRequest) => ({
        url: "/events/groups",
        body: eventRequest,
        method: "POST",
      }),
    }),
    postGraph: builder.mutation<GraphResponse[], GraphRequest>({
      query: (graphRequest) => ({
        url: "/signalprint/reporting/graph",
        body: graphRequest,
        method: "POST",
      }),
    }),
    postEventHistory: builder.mutation({
      query: ({ payload }) => ({
        url: "/signalprint/reporting/eventHistory",
        body: payload,
        method: "POST",
      }),
    }),
    postGraphAttribute: builder.mutation<AttributeResponse, AttributeRequest>({
      query: (payload) => ({
        url: "/signalprint/reporting/attribute",
        body: payload,
        method: "POST",
      }),
    }),
    getApplication: builder.query({
      query: ({ uuid }) =>
        `/client-services-api/application?application_uuid=${uuid}`,
    }),
    getVerificationProviders: builder.query<
      VerificationProvidersResponse,
      void
    >({
      query: () => ({
        url: "/verification/providers",
        method: "GET",
      }),
      providesTags: ["VerificationProviders"],
    }),
    postCreateVerificationProvider: builder.mutation({
      query: ({ payload }) => ({
        url: "/verification/providers",
        body: payload,
        method: "POST",
      }),
      invalidatesTags: ["VerificationProviders"],
    }),
    putEditVerificationProvider: builder.mutation({
      query: ({ payload }) => ({
        url: `/verification/providers/${payload.uuid}`,
        body: payload,
        method: "PUT",
      }),
      invalidatesTags: ["VerificationProviders"],
    }),
    deleteVerificationProvider: builder.mutation({
      query: (params) => ({
        url: `/verification/providers/${params.uuid}`,
        method: "DELETE",
      }),
      invalidatesTags: ["VerificationProviders"],
    }),
    postCreateRule: builder.mutation({
      query: ({ payload }) => ({
        url: "/rules/rule-sets",
        body: payload,
        method: "POST",
      }),
      invalidatesTags: ["Rule"],
    }),
    postValidateRule: builder.mutation({
      query: (rules) => ({
        url: "/rules/validate",
        body: rules,
        method: "POST",
      }),
    }),
    getRulesDictionary: builder.query<DataDictionaryResponse, void>({
      query: () => "/rules/dictionary",
      providesTags: ["RulesDictionary"],
    }),
    getRules: builder.query({
      query: () => "/rules/rule-sets",
      providesTags: ["Rule"],
    }),
    getRule: builder.query({
      query: (params) => `/rules/rule-sets/${params.uuid}`,
    }),
    putRule: builder.mutation({
      query: (payload) => ({
        url: `/rules/rule-sets/${payload.uuid}`,
        body: payload,
        method: "PUT",
      }),
      invalidatesTags: ["Rule"],
    }),
    deleteRule: builder.mutation({
      query: (params) => ({
        url: `/rules/rule-sets/${params.uuid}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Rule"],
    }),
    postCreateClient: builder.mutation({
      query: ({ payload }) => ({
        url: "/client-services-api/client",
        body: payload,
        method: "POST",
      }),
    }),
    putClient: builder.mutation({
      query: ({ payload }) => ({
        url: "/client-services-api/client",
        body: payload,
        method: "PUT",
      }),
      invalidatesTags: ["Client", "Profile"],
    }),
    getClient: builder.query<Client, void>({
      query: () => ({
        url: "/client-services-api/client",
        method: "GET",
      }),
      providesTags: ["Client"],
    }),
    getMetrics: builder.query<Metrics, void>({
      query: () => ({
        url: "/client-services-api/client/metrics",
        method: "GET",
      }),
      providesTags: ["Metrics"],
    }),
    getMetricsFresh: builder.query<Metrics, void>({
      query: () => ({
        url: "/client-services-api/client/metrics",
        method: "GET",
      }),
      keepUnusedDataFor: 0,
    }),
    getProfile: builder.query<Profile, void>({
      query: () => ({
        url: "/client-services-api/profile",
        method: "GET",
      }),
      providesTags: ["Profile"],
    }),
    putProfile: builder.mutation({
      query: ({ payload }) => ({
        url: "/client-services-api/profile",
        body: payload,
        method: "PUT",
      }),
      invalidatesTags: ["Profile"],
    }),
    getInsights: builder.query<InsightsResponse, boolean>({
      query: (archived = false) => ({
        url: `/signalprint/insights?archived=${archived}`,
        method: "GET",
      }),
      providesTags: ["Insights"],
    }),
    postInsightsFeedback: builder.mutation<void, InsightsFeedback>({
      query: ({ payload, insightID, insightType }) => ({
        url: `/signalprint/insights/${insightType}/${insightID}/feedback`,
        body: payload,
        method: "POST",
      }),
      invalidatesTags: ["Insights"],
    }),
    getThreatInsights: builder.query<ThreatResponse, any>({
      query: ({ resolved = false, start, end }) => {
        const params = new URLSearchParams({ resolved: String(resolved) });
        if (start) params.append("start", start);
        if (end) params.append("end", end);

        return {
          url: `/insights/threats?${params.toString()}`,
          method: "GET",
        };
      },
      providesTags: ["Threats"],
    }),
    getThreat: builder.query<Threat, string>({
      query: (params) => ({
        url: `/insights/threats/${params}`,
        method: "GET",
      }),
      providesTags: ["Threats"],
    }),
    postThreatFeedback: builder.mutation<void, InsightsFeedback>({
      query: ({ payload, insightID }) => ({
        url: `/insights/threats/${insightID}/feedback`,
        body: payload,
        method: "POST",
      }),
      invalidatesTags: ["Threats"],
    }),
    postSupportEmail: builder.mutation({
      query: ({ payload }) => ({
        url: "/client-services-api/contact-support",
        body: payload,
        method: "POST",
      }),
    }),
    getWorkspaceUsers: builder.query<GetWorkspaceUsersResponse, void>({
      query: () => ({
        url: "/client-services-api/users",
        method: "GET",
      }),
      providesTags: ["Users"],
    }),
    putWorkspaceUser: builder.mutation<
      UpdateWorkspaceUserResponse,
      {
        payload: UpdateWorkspaceUserRequest;
        userId: string;
      }
    >({
      query: ({ payload, userId }) => ({
        url: `/client-services-api/users/${userId}`,
        body: payload,
        method: "PUT",
      }),
      invalidatesTags: ["Users"],
    }),
    deleteWorkspaceUser: builder.mutation<void, { userId: string }>({
      query: ({ userId }) => ({
        url: `/client-services-api/users/${userId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Users"],
    }),
    getInvitations: builder.query<GetInvitationsResponse, void>({
      query: () => ({
        url: "/client-services-api/invitations",
        method: "GET",
      }),
      providesTags: ["Invitations"],
    }),
    postInvitations: builder.mutation<
      CreateInvitationsResponse,
      CreateInvitationsRequest
    >({
      query: (payload) => ({
        url: "/client-services-api/invitations",
        body: payload,
        method: "POST",
      }),
      invalidatesTags: ["Invitations"],
    }),
    putInvitation: builder.mutation<
      UpdateInvitationResponse,
      {
        payload: UpdateInvitationRequest;
        invitationId: string;
      }
    >({
      query: ({ payload, invitationId }) => ({
        url: `/client-services-api/invitations/${invitationId}`,
        body: payload,
        method: "PUT",
      }),
      invalidatesTags: ["Invitations"],
    }),
    deleteInvitation: builder.mutation<void, { invitationId: string }>({
      query: ({ invitationId }) => ({
        url: `/client-services-api/invitations/${invitationId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Invitations"],
    }),
    postExcludeFilters: builder.mutation({
      query: ({ payload }) => ({
        url: "/signalprint/excludefilters",
        body: payload,
        method: "POST",
      }),
      invalidatesTags: ["ExcludeFilters"],
    }),
    getExcludeFilters: builder.query({
      query: () => ({
        url: "/signalprint/excludefilters",
        method: "GET",
      }),
      providesTags: ["ExcludeFilters"],
    }),
    deleteExcludeFilters: builder.mutation({
      query: ({ filterId }) => ({
        url: `/signalprint/excludefilters/${filterId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ExcludeFilters"],
    }),
    putExcludeFilters: builder.mutation({
      query: ({ payload }) => ({
        url: "/signalprint/excludefilters",
        body: payload,
        method: "PUT",
      }),
      invalidatesTags: ["ExcludeFilters"],
    }),
    postCreateList: builder.mutation({
      query: ({ payload }) => ({
        url: "/signalprint/lists",
        body: payload,
        method: "POST",
      }),
      invalidatesTags: ["Lists"],
    }),
    getLists: builder.query<ListItemResponse, any>({
      query: () => "/signalprint/lists",
      providesTags: ["Lists"],
    }),
    getList: builder.query<ListInfo, string>({
      query: (params) => `/signalprint/lists/${params}`,
      providesTags: ["Lists"],
    }),
    putUpdateList: builder.mutation({
      query: ({ params, payload }) => ({
        url: `/signalprint/lists/${params}`,
        body: payload,
        method: "PUT",
      }),
      invalidatesTags: ["Lists"],
    }),
    deleteList: builder.mutation({
      query: (params) => ({
        url: `/signalprint/lists/${params}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Lists"],
    }),
    getListAttributes: builder.query<ListAttributesResponse, string>({
      query: (params) => `/signalprint/lists/${params}/attributes`,
    }),
    postListAttributes: builder.mutation({
      query: ({ params, payload }) => ({
        url: `/signalprint/lists/${params}/attributes`,
        body: payload,
        method: "POST",
      }),
      invalidatesTags: ["Lists"],
    }),
    deleteListAttributes: builder.mutation({
      query: ({ params, payload }) => ({
        url: `/signalprint/lists/${params}/attributes`,
        body: payload,
        method: "DELETE",
      }),
      invalidatesTags: ["Lists"],
    }),
    postEvaluateListMembership: builder.mutation({
      query: ({ payload }) => ({
        url: `/signalprint/lists/evaluate`,
        body: payload,
        method: "POST",
      }),
    }),
    postAccountConnections: builder.mutation({
      query: ({ payload }) => ({
        url: `/signalprint/reporting/accountConnections`,
        body: payload,
        method: "POST",
      }),
    }),
    postCreateNotification: builder.mutation<any, NotificationType>({
      query: (payload) => ({
        url: "/client-services-api/notifications",
        body: payload,
        method: "POST",
      }),
      invalidatesTags: ["Notifications"],
    }),
    getNotifications: builder.query<NotificationType[], any>({
      query: () => "/client-services-api/notifications",
      providesTags: ["Notifications"],
    }),
    putUpdateNotification: builder.mutation<any, NotificationType>({
      query: (payload) => ({
        url: `/client-services-api/notifications/${payload.uuid}`,
        body: payload,
        method: "PUT",
      }),
      invalidatesTags: ["Notifications"],
    }),
    deleteNotification: builder.mutation({
      query: (params) => ({
        url: `/client-services-api/notifications/${params}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Notifications"],
    }),
    postCreateIdentityIntegration: builder.mutation<any, IAMProvider>({
      query: (payload) => ({
        url: "/client-services-api/identityIntegrations",
        body: payload,
        method: "POST",
      }),
      invalidatesTags: ["IdentityIntegrations"],
    }),
    getIdentityIntegrations: builder.query<IAMProvider[], any>({
      query: () => "/client-services-api/identityIntegrations",
      providesTags: ["IdentityIntegrations"],
    }),
    putUpdateIdentityIntegration: builder.mutation<any, IAMProvider>({
      query: (payload) => ({
        url: `/client-services-api/identityIntegrations/${payload.id}`,
        body: payload,
        method: "PUT",
      }),
      invalidatesTags: ["IdentityIntegrations"],
    }),
    deleteIdentityIntegration: builder.mutation({
      query: (params) => ({
        url: `/client-services-api/identityIntegrations/${params.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["IdentityIntegrations"],
    }),
    getAccountSession: builder.query<any[], any>({
      // TODO: add types
      query: (params) =>
        `/client-services-api/identityProvider/accounts/${params.accountId}/sessions/${params.sessionId}`,
      providesTags: ["IdentityProvider"],
    }),
    deleteAccountSession: builder.mutation({
      query: (params) => ({
        url: `/client-services-api/identityProvider/accounts/${params.accountId}/sessions/${params.sessionId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["IdentityProvider"],
    }),
    getAccountSessions: builder.query<any[], any>({
      query: (params) =>
        `/client-services-api/identityProvider/accounts/${params.accountId}/sessions`,
      providesTags: ["IdentityProvider"],
    }),
    deleteAccountSessions: builder.mutation({
      query: (params) => ({
        url: `/client-services-api/identityProvider/accounts/${params.accountId}/sessions`,
        method: "DELETE",
      }),
      invalidatesTags: ["IdentityProvider"],
    }),
    getAccountFromIDP: builder.query<any[], any>({
      query: (params) =>
        `/client-services-api/identityProvider/accounts/${params.accountId}`,
      providesTags: ["IdentityProvider"],
    }),
    putUpdateIdentityProviderAccount: builder.mutation<any, any>({
      query: ({ payload, params }) => ({
        url: `/client-services-api/identityProvider/accounts/${params.accountId}`,
        body: payload,
        method: "PUT",
      }),
      invalidatesTags: ["IdentityProvider"],
    }),
    getSiemIntegrations: builder.query<any[], any>({
      // TODO: add types?
      query: () => `/client-services-api/siemIntegrations`,
      providesTags: ["SiemIntegrations"],
    }),
    postCreateSiemIntegration: builder.mutation<any, any>({
      query: (payload) => ({
        url: "/client-services-api/siemIntegrations",
        body: payload,
        method: "POST",
      }),
      invalidatesTags: ["SiemIntegrations"],
    }),
    putUpdateSiemIntegration: builder.mutation<any, any>({
      query: (payload) => ({
        url: `/client-services-api/siemIntegrations/${payload.id}`,
        body: payload,
        method: "PUT",
      }),
      invalidatesTags: ["SiemIntegrations"],
    }),
    deleteSiemIntegration: builder.mutation({
      query: (params) => ({
        url: `/client-services-api/siemIntegrations/${params.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["SiemIntegrations"],
    }),
    sendTestSiemNotification: builder.mutation<any, any>({
      query: (payload) => ({
        url: "/client-services-api/siemIntegrations/test",
        body: payload,
        method: "POST",
      }),
    }),
    postResolveThreat: builder.mutation<void, any>({
      query: ({ payload, params }) => ({
        url: `/insights/threats/${params.id}/resolve`,
        body: payload,
        method: "POST",
      }),
      invalidatesTags: ["Threats"],
    }),
    postReopenThreat: builder.mutation<void, any>({
      query: ({ threatId }) => ({
        url: `/insights/threats/${threatId}/reopen`,
        method: "POST",
      }),
      invalidatesTags: ["Threats"],
    }),
  }),
});

export const {
  useLazyGetStripeURLQuery,
  useGetApplicationsQuery,
  useLazyGetTypeaheadQuery,
  useGetVerificationProvidersQuery,
  usePostGraphSearchMutation,
  useGetEventFieldsQuery,
  usePostSuggestionsMutation,
  usePostEventSearchMutation,
  usePostEventGroupSearchMutation,
  usePostGraphMutation,
  usePostEventHistoryMutation,
  usePostGraphAttributeMutation,
  usePostCreateRuleMutation,
  usePostCreateVerificationProviderMutation,
  usePutEditVerificationProviderMutation,
  useDeleteVerificationProviderMutation,
  usePostValidateRuleMutation,
  useGetRulesDictionaryQuery,
  useGetRulesQuery,
  useLazyGetRuleQuery,
  usePutRuleMutation,
  useDeleteRuleMutation,
  usePostCreateClientMutation,
  usePutClientMutation,
  useGetClientQuery,
  useGetMetricsQuery,
  useGetMetricsFreshQuery,
  useGetProfileQuery,
  usePutProfileMutation,
  useGetInsightsQuery,
  useLazyGetInsightsQuery,
  useGetThreatInsightsQuery,
  useGetThreatQuery,
  useLazyGetThreatQuery,
  useLazyGetThreatInsightsQuery,
  usePostThreatFeedbackMutation,
  usePostInsightsFeedbackMutation,
  usePostSupportEmailMutation,
  useGetWorkspaceUsersQuery,
  usePutWorkspaceUserMutation,
  useDeleteWorkspaceUserMutation,
  useGetInvitationsQuery,
  usePostInvitationsMutation,
  usePutInvitationMutation,
  useDeleteInvitationMutation,
  usePostExcludeFiltersMutation,
  useGetExcludeFiltersQuery,
  useDeleteExcludeFiltersMutation,
  usePutExcludeFiltersMutation,
  usePostCreateListMutation,
  useGetListsQuery,
  usePutUpdateListMutation,
  useLazyGetListQuery,
  useLazyGetListAttributesQuery,
  useDeleteListMutation,
  usePostListAttributesMutation,
  useDeleteListAttributesMutation,
  usePostEvaluateListMembershipMutation,
  usePostAccountConnectionsMutation,
  usePostCreateNotificationMutation,
  useGetNotificationsQuery,
  usePutUpdateNotificationMutation,
  useDeleteNotificationMutation,
  usePostCreateIdentityIntegrationMutation,
  useGetIdentityIntegrationsQuery,
  usePutUpdateIdentityIntegrationMutation,
  useDeleteIdentityIntegrationMutation,
  useGetAccountSessionQuery,
  useDeleteAccountSessionMutation,
  useGetAccountSessionsQuery,
  useDeleteAccountSessionsMutation,
  useGetAccountFromIDPQuery,
  usePutUpdateIdentityProviderAccountMutation,
  useGetSiemIntegrationsQuery,
  usePostCreateSiemIntegrationMutation,
  usePutUpdateSiemIntegrationMutation,
  useDeleteSiemIntegrationMutation,
  useSendTestSiemNotificationMutation,
  usePostResolveThreatMutation,
  usePostReopenThreatMutation,
} = fftIdApi;
